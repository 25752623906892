.slot{
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    max-width: 800px;

    background-color: white;

}

.slotDisplay{
    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: 1em;
    outline: none;

    border-bottom: 1px solid black;
    background-color: rgba(155,155,155,0.5);
}
