.slotContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
}

@media only screen and (max-width: 1200px){
    .slotContainer{
        flex-direction: column;
    }
}