.menuBtn {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 2.5rem;
    padding: .25em .5em;
    margin: 0.5rem;
    border: 0px solid black;
    border-radius: 35%;
    color: white;
    background-color:#132029;
    cursor: pointer;
    transition: .25s;
}

.menuBtn:hover{
    border: 0px solid black;
    border-radius: 35%;
    background-color:#192a36;
}